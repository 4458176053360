<template>
  <div style="width: 100vw; height: 100vh; display: flex; justifyContent: center; alignItems: center;">
    <svg xmlns:svg="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.0" width="80px" height="80px" viewBox="0 0 128 128" xml:space="preserve">
      <rect x="0" y="0" width="100%" height="100%" fill="#FFFFFF" />
      <g>
        <path d="M75.4 126.63a11.43 11.43 0 0 1-2.1-22.65 40.9 40.9 0 0 0 30.5-30.6 11.4 11.4 0 1 1 22.27 4.87h.02a63.77 63.77 0 0 1-47.8 48.05v-.02a11.38 11.38 0 0 1-2.93.37z" fill="#2267d6" />
        <animateTransform attributeName="transform" type="rotate" from="0 64 64" to="360 64 64" dur="1000ms" repeatCount="indefinite">
        </animateTransform>
      </g>
    </svg>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import { errorHandler } from "../../utils/helpers";

export default {
  name: "Transaction",
  methods: {
    ...mapActions([
      'confirmBooking'
    ])
  },
  async mounted() { 
    const {reference} = this.$route.query;

    try {
      const result = await this.confirmBooking(reference);

      this.$toast.success(`${result.message}`);

      location.href = `${location.origin}/${localStorage.getItem('user_profile_url')}`;

    } catch (error) {
      errorHandler(error, this.$toast);
    }
  },
};
</script>

<style lang="scss">
</style>